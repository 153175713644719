import React, { useState } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import MassMessageValidationPage from './MassMessageValidationPage';
import ModelTable from './ModelTable';
import WhisperForm from './WhisperForm';

interface AdminPageProps {
    authToken: string;
}

const AdminPage: React.FC<AdminPageProps> = ({ authToken }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [selectedTab, setSelectedTab] = useState<string | null>('signup'); // Default tab

    const tabs = [
        { id: 'signup', label: 'Sign Up' },
        { id: 'models', label: 'Models' },
        // Add more tabs here
    ];

    const currentTab = location.pathname.split('/').pop();

    const signUpFields = [
        { name: 'name', type: 'text', placeholder: 'Stage name', required: true },
        { name: 'email', type: 'email', placeholder: 'Email', required: true },
        { name: 'password', type: 'password', placeholder: 'Password', required: true },
        { name: 'gauthKey', type: 'text', placeholder: 'Google auth key', required: false }, // Base64 key, so use type 'text'
        { name: 'contactEmail', type: 'email', placeholder: 'Contact email', required: true },
        {
            name: 'grossCommissionFraction',
            type: 'number',
            placeholder: 'Commission (e.g. .15)',
            min: 0.01,
            max: 0.99,
            step: 0.01,
            required: true
        },
        { name: 'couponCode', type: 'text', placeholder: 'Coupon code (optional)', required: false },
        { name: 'rfsnValue', type: 'text', placeholder: 'rfsn value (optional)', required: false }
    ];

    return (
        <div>
            {/* Tabs */}
            <div className="tabs">
                {tabs.map((tab) => (
                    <button
                        key={tab.id}
                        onClick={() => navigate(`/admin/${tab.id}`)}
                        style={{
                            fontWeight: currentTab === tab.id ? 'bold' : 'normal',
                        }}
                    >
                        {tab.label}
                    </button>
                ))}
            </div>

            {/* Content */}
            <div className="tab-content">
                <Routes>
                    <Route
                        path="signup"
                        element={
                            <>
                                <h1>{"On-board"}</h1>
                                <WhisperForm
                                    fields={signUpFields}
                                    token={authToken}
                                    submitUrl={"/api/restricted/workflow/onboard"}
                                    buttonText="On-board"
                                    onSuccess={() => console.log("On-board successful!")}
                                    onError={() => console.error("On-board failed!")}
                                />
                            </>
                        }
                    />
                    <Route
                        path="models"
                        element={<ModelTable authToken={authToken} />}
                    />
                    <Route
                        path="massmessage/:modelId"
                        element={<MassMessageValidationPage authToken={authToken} />}
                    />
                    {/* Default route */}
                    <Route
                        path=""
                        element={<h2>Welcome to the Admin Page</h2>}
                    />
                </Routes>
            </div>
        </div>
    );
};

export default AdminPage;
