import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

export interface ModelInfo {
    id: number;
    name: string;
    email: string;
    password: string;
    gauthKey: string;
    external_id: string;
    active: boolean;
    running: boolean;
    personality: string;
    base_media_price_in_cents: number;
    creator_facts: string;
    escalate_words: string[];
    photo_price_discount: number;
    total_spend_cap_dollars: number;
    global_price_discount: number;
    mms_per_day: number;
    excluded_external_fan_collection_ids: string[];
}

interface ModelInfoRowProps {
    modelInfo: ModelInfo;
    authToken: string;
    isAdmin: boolean;
}


const ModelInfoRow: React.FC<ModelInfoRowProps> = ({ modelInfo, authToken, isAdmin }) => {
    const navigate = useNavigate();
    const [isRunning, setIsRunning] = useState<boolean>(modelInfo.running);
    const [isActive, setIsActive] = useState<boolean>(modelInfo.active)
    const [statusMessage, setStatusMessage] = useState<string>('');
    const [isFlowBuilderStarted, setIsFlowBuilderStarted] = useState(false);
    const [isVaultBuilderStarted, setIsVaultBuilderStarted] = useState(false);
    const [isMMBuilderStarted, setIsMMBuilderStarted] = useState(false);
    const [isScraperStarted, setIsScraperStarted] = useState(false);
    const [isBrowserProfileRefreshStarted, setIsBrowserProfileRefreshStarted] = useState(false);

    const initialEscalateWordsStr = modelInfo.escalate_words.join(', ');

    const initialIdsStr = (modelInfo.excluded_external_fan_collection_ids || []).join(', ');


    // Editable fields
    const [editableFields, setEditableFields] = useState({
        name: modelInfo.name,
        password: modelInfo.password,
        gauthKey: modelInfo.gauthKey,
        personality: modelInfo.personality,
        base_media_price_in_cents: modelInfo.base_media_price_in_cents,
        creator_facts: modelInfo.creator_facts,
        escalate_words_str: initialEscalateWordsStr,
        photo_price_discount: modelInfo.photo_price_discount,
        total_spend_cap_dollars: modelInfo.total_spend_cap_dollars,
        global_price_discount: modelInfo.global_price_discount,
        mms_per_day: modelInfo.mms_per_day,
        excluded_external_fan_collection_ids_str: initialIdsStr,
    });

    const [isSavingFields, setIsSavingFields] = useState(false);
    const [fieldsStatus, setFieldsStatus] = useState<string>('');

    const handleFieldChange = (fieldName: keyof typeof editableFields, value: string) => {
        setEditableFields((prev) => {
            let newValue: string | number = value;
            if (
                fieldName === 'base_media_price_in_cents' ||
                fieldName === 'total_spend_cap_dollars' ||
                fieldName === 'mms_per_day'
            ) {
                newValue = parseInt(value, 10) || 0;
            } else if (
                fieldName === 'photo_price_discount' ||
                fieldName === 'global_price_discount'
            ) {
                newValue = parseFloat(value) || 0.0;
            }
            return {
                ...prev,
                [fieldName]: newValue
            }
        });
    };

    const handleMassMessagesClick = () => {
        navigate(`/admin/massmessage/${modelInfo.id}`);
    };

    const handleDeactivateClick = async () => {
        // Show a confirmation dialog to the user
        const confirm = window.confirm(
            "Are you sure you want to deactivate this model? This action is ONLY for client offboarding and cannot be undone."
        );

        // Proceed only if the user confirms
        if (!confirm) {
            return; // Exit the function if the user cancels
        }

        try {
            const response = await axios({
                method: 'post',
                url: `/api/restricted/models/${modelInfo.id}/deactivate`,
                headers: {
                    'Authorization': `Bearer ${authToken}`
                }
            });
            console.log('Deactivated the model:', response.data);
            setIsActive(false);
        } catch (error) {
            console.error('An error occurred while deactivating the model:', error);
        }
    };

    const handleStopClick = async () => {
        try {
            const response = await axios({
                method: 'post',
                url: `/api/restricted/models/${modelInfo.id}/stop`,
                headers: {
                    'Authorization': `Bearer ${authToken}`
                }
            });
            console.log('Stopped the model:', response.data);
            setIsRunning(false);
            setStatusMessage('Model successfully stopped');
        } catch (error) {
            console.error('An error occurred while stopping the model:', error);
        }
    };

    const handleStartClick = async () => {
        var url = `/api/restricted/models/${modelInfo.id}/startChatter`
        try {
            const response = await axios({
                method: 'post',
                url: url,
                headers: {
                    'Authorization': `Bearer ${authToken}`
                }
            });
            console.log('Started the model:', response.data);
            setIsRunning(true);
            setStatusMessage('Model successfully started');
        } catch (error) {
            console.error('An error occurred while starting the model:', error);
        }
    };

    const handleFlowBuilderStart = async () => {
        try {
            const response = await axios({
                method: 'post',
                url: `/api/restricted/models/${modelInfo.id}/buildFlows`,
                headers: {
                    'Authorization': `Bearer ${authToken}`
                }
            });
            console.log('Started flow builder:', response.data);
            setIsFlowBuilderStarted(true);
        } catch (error) {
            console.error('An error occurred while starting flow builder:', error);
        }
    };

    const handleVaultBuilderStart = async () => {
        try {
            const response = await axios({
                method: 'post',
                url: `/api/restricted/models/${modelInfo.id}/buildVault`,
                headers: {
                    'Authorization': `Bearer ${authToken}`
                }
            });
            console.log('Started vault builder:', response.data);
            setIsVaultBuilderStarted(true);
        } catch (error) {
            console.error('An error occurred while starting vault builder:', error);
        }
    };

    const handleMMBuilderStart = async () => {
        try {
            const response = await axios({
                method: 'post',
                url: `/api/restricted/models/${modelInfo.id}/buildMMs`,
                headers: {
                    'Authorization': `Bearer ${authToken}`
                }
            });
            console.log('Started MM builder:', response.data);
            setIsMMBuilderStarted(true);
        } catch (error) {
            console.error('An error occurred while starting MM builder:', error);
        }
    };

    const handleScraperStart = async () => {
        try {
            const response = await axios({
                method: 'post',
                url: `/api/restricted/models/${modelInfo.id}/scrape`,
                headers: {
                    'Authorization': `Bearer ${authToken}`
                }
            });
            console.log('Started scraper:', response.data);
            setIsScraperStarted(true);
        } catch (error) {
            console.error('An error occurred while starting scraper:', error);
        }
    };

    const handleBrowserProfileRefreshStart = async () => {
        try {
            const response = await axios({
                method: 'post',
                url: `/api/restricted/models/${modelInfo.id}/refreshBrowserProfiles`,
                headers: {
                    'Authorization': `Bearer ${authToken}`
                }
            });
            console.log('Started browser profile refresh:', response.data);
            setIsBrowserProfileRefreshStarted(true);
        } catch (error) {
            console.error('An error occurred while starting browser profile refresh:', error);
        }
    };

    // Single function to save all fields
    const handleSaveAllFields = async () => {
        setIsSavingFields(true);
        setFieldsStatus('');
        // Convert escalate_words_str back to array
        const escalateWordsArray = editableFields.escalate_words_str
            .split(',')
            .map((word) => word.trim())
            .filter((word) => word.length > 0);

        const escalateListIDsArray = editableFields.excluded_external_fan_collection_ids_str
            .split(',')
            .map(idStr => parseInt(idStr.trim(), 10))
            .filter(num => !isNaN(num));
        try {
            const response = await axios({
                method: 'post',
                url: `/api/restricted/models/${modelInfo.id}/updateFields`,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
                data: {
                    name: editableFields.name,
                    password: editableFields.password,
                    gauthKey: editableFields.gauthKey,
                    personality: editableFields.personality,
                    base_media_price_in_cents: Number(editableFields.base_media_price_in_cents),
                    creator_facts: editableFields.creator_facts,
                    escalate_words: escalateWordsArray,
                    photo_price_discount: Number(editableFields.photo_price_discount),
                    total_spend_cap_dollars: Number(editableFields.total_spend_cap_dollars),
                    global_price_discount: Number(editableFields.global_price_discount),
                    mms_per_day: Number(editableFields.mms_per_day),
                    excluded_external_fan_collection_ids: escalateListIDsArray,
                }
            });
            console.log('Fields updated:', response.data);
            setFieldsStatus('All fields successfully updated.');
        } catch (error) {
            console.error('Error updating fields:', error);
            setFieldsStatus('Failed to update fields.');
        } finally {
            setIsSavingFields(false);
        }
    };

    return (
        <div>
            <h1>{modelInfo.name}</h1>
            <p>Email: {modelInfo.email}</p>
            <p>Internal Whisper ID: {modelInfo.id}</p>
            <p>External ID: {modelInfo.external_id}</p>
            <div>
                <p>Active: {isActive ? 'Yes' : 'No'}</p>
                {isAdmin && isActive && (
                    <button
                        style={{ backgroundColor: 'orange', marginLeft: '20px' }}
                        onClick={handleDeactivateClick}
                    >
                        Deactivate
                    </button>
                )}
            </div>
            <div>
                <p style={{ display: 'inline-block' }}>Running: {isRunning ? 'Yes' : 'No'}</p>
                {isRunning && (
                    <button
                        style={{ backgroundColor: 'red', marginLeft: '20px' }}
                        onClick={handleStopClick}
                    >
                        Stop
                    </button>
                )}
                {isActive && !isRunning && (
                    <button
                        style={{ backgroundColor: 'green', marginLeft: '20px' }}
                        onClick={() => handleStartClick()}
                    >
                        Start
                    </button>
                )}
                <p>{statusMessage}</p>
            </div>

            {/* Editable Fields Section */}
            {isAdmin && (
                <div style={{ marginTop: '20px' }}>
                    <h3>Edit Fields</h3>
                    <div style={{ marginBottom: '10px' }}>
                        <label>Stage Name:</label><br />
                        <textarea
                            value={editableFields.name}
                            onChange={(e) => handleFieldChange('name', e.target.value)}
                            rows={3}
                            cols={50}
                        />
                    </div>

                    <div style={{ marginBottom: '10px' }}>
                        <label>Password:</label><br />
                        <textarea
                            value={editableFields.password}
                            onChange={(e) => handleFieldChange('password', e.target.value)}
                            cols={50}
                        />
                    </div>

                    <div style={{ marginBottom: '10px' }}>
                        <label>Google Authentiator Key:</label><br />
                        <textarea
                            value={editableFields.gauthKey}
                            onChange={(e) => handleFieldChange('gauthKey', e.target.value)}
                            cols={50}
                        />
                    </div>

                    <div style={{ marginBottom: '10px' }}>
                        <label>Personality:</label><br />
                        <textarea
                            value={editableFields.personality}
                            onChange={(e) => handleFieldChange('personality', e.target.value)}
                            rows={3}
                            cols={50}
                        />
                    </div>

                    <div style={{ marginBottom: '10px' }}>
                        <label>Base Media Price (in cents):</label><br />
                        <input
                            type="number"
                            value={editableFields.base_media_price_in_cents}
                            onChange={(e) => handleFieldChange('base_media_price_in_cents', e.target.value)}
                        />
                    </div>

                    <div style={{ marginBottom: '10px' }}>
                        <label>Creator Facts:</label><br />
                        <textarea
                            value={editableFields.creator_facts}
                            onChange={(e) => handleFieldChange('creator_facts', e.target.value)}
                            rows={3}
                            cols={50}
                        />
                    </div>

                    <div style={{ marginBottom: '10px' }}>
                        <label>Escalate Words (comma-separated):</label><br />
                        <textarea
                            value={editableFields.escalate_words_str}
                            onChange={(e) => handleFieldChange('escalate_words_str', e.target.value)}
                            rows={3}
                            cols={50}
                        />
                    </div>

                    <div style={{ marginBottom: '10px' }}>
                        <label>Photo Price Discount:</label><br />
                        <input
                            type="number"
                            step="0.01"
                            value={editableFields.photo_price_discount}
                            onChange={(e) => handleFieldChange('photo_price_discount', e.target.value)}
                        />
                    </div>

                    <div style={{ marginBottom: '10px' }}>
                        <label>Total Spend Cap (in dollars):</label><br />
                        <input
                            type="number"
                            value={editableFields.total_spend_cap_dollars}
                            onChange={(e) => handleFieldChange('total_spend_cap_dollars', e.target.value)}
                        />
                    </div>

                    <div style={{ marginBottom: '10px' }}>
                        <label>Global Price Discount:</label><br />
                        <input
                            type="number"
                            step="0.01"
                            value={editableFields.global_price_discount}
                            onChange={(e) => handleFieldChange('global_price_discount', e.target.value)}
                        />
                    </div>

                    <div style={{ marginBottom: '10px' }}>
                        <label>MMs Per Day:</label><br />
                        <input
                            type="number"
                            value={editableFields.mms_per_day}
                            onChange={(e) => handleFieldChange('mms_per_day', e.target.value)}
                        />
                    </div>

                    <div style={{ marginBottom: '10px' }}>
                        <label>Escalate List IDs (comma-separated):</label><br />
                        <textarea
                            value={editableFields.excluded_external_fan_collection_ids_str}
                            onChange={(e) => handleFieldChange('excluded_external_fan_collection_ids_str', e.target.value)}
                            rows={3}
                            cols={50}
                        />
                    </div>

                    <button
                        onClick={handleSaveAllFields}
                        disabled={isSavingFields}
                        style={{ backgroundColor: 'orange', color: 'white' }}
                    >
                        {isSavingFields ? 'Saving...' : 'Save All Fields'}
                    </button>
                    {fieldsStatus && <p>{fieldsStatus}</p>}
                </div>
            )}

            {isAdmin && (
                <button
                    style={{ backgroundColor: 'purple', color: 'white', marginTop: '20px' }}
                    onClick={handleMassMessagesClick}
                >
                    Validate Mass Messages
                </button>
            )}
            <div>
                {isAdmin && !isFlowBuilderStarted ? (
                    <button
                        style={{ backgroundColor: 'blue', color: 'white', marginTop: '20px' }}
                        onClick={handleFlowBuilderStart}
                    >
                        Start Flow Builder
                    </button>
                ) : (
                    <p>Flow builder started</p>
                )}
            </div>
            <div>
                {isAdmin && !isVaultBuilderStarted ? (
                    <button
                        style={{ backgroundColor: 'blue', color: 'white', marginTop: '20px' }}
                        onClick={handleVaultBuilderStart}
                    >
                        Start Vault builder
                    </button>
                ) : (
                    <p>Vault builder started</p>
                )}
            </div>
            <div>
                {isAdmin && !isMMBuilderStarted ? (
                    <button
                        style={{ backgroundColor: 'blue', color: 'white', marginTop: '20px' }}
                        onClick={handleMMBuilderStart}
                    >
                        Start MM builder
                    </button>
                ) : (
                    <p>MM builder started</p>
                )}
            </div>
            <div>
                {isAdmin && !isScraperStarted ? (
                    <button
                        style={{ backgroundColor: 'blue', color: 'white', marginTop: '20px' }}
                        onClick={handleScraperStart}
                    >
                        Start Scraper
                    </button>
                ) : (
                    <p>Scraper started</p>
                )}
            </div>
            <div>
                {isAdmin && !isBrowserProfileRefreshStarted ? (
                    <button
                        style={{ backgroundColor: 'blue', color: 'white', marginTop: '20px' }}
                        onClick={handleBrowserProfileRefreshStart}
                    >
                        Start Browser Profile Refresh
                    </button>
                ) : (
                    <p>Browser profile refresh started</p>
                )}
            </div>
        </div>
    );
};

export default ModelInfoRow;
